import { ActivityComponentType } from '@stackflow/react'

import { karrotBridge } from '@src/bridge'
import { Screen, useActivity, useNavigator } from '@src/stackflow'

import { EmptyOrError } from '../EmptyOrError'

export const FallbackScreen: ActivityComponentType = () => {
  const { pop } = useNavigator()
  const { isRoot } = useActivity()

  const close = () => {
    if (isRoot) {
      karrotBridge.closeRouter({})
    } else {
      pop()
    }
  }

  return (
    <Screen>
      <EmptyOrError
        body={'페이지를 찾을 수 없습니다.'}
        CTAButton={{
          label: '뒤로가기',
          onClick: close,
        }}
      />
    </Screen>
  )
}
