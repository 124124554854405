import {
  getByPoiV1,
  ResponseLpRestResponse as LocalProfileResponse,
} from '@daangn/local-business-network/lib/businessAccount'
import { createLoadableFamily, useLoadableFamily } from '@daangn/recoil-loadable'

type LocalProfileLoadableState = LocalProfileResponse | null

export const localProfileByPoiIdFetcher = createLoadableFamily<
  LocalProfileLoadableState,
  number,
  LocalProfileResponse | null
>({
  getStore: ({ atomFamily }) => atomFamily({ key: 'localProfileFetcher' }),
  fetch: async ({ variables: poiId }) => {
    try {
      const response = await getByPoiV1({
        params: {
          poiId,
        },
      })

      return response.data ?? null
    } catch {
      return null
    }
  },
  onSuccess: ({ response }) => response,
})

const useLoadableLocalProfileByPoiId = (poiId: number) => {
  const { value: localProfileByPoiIdResponse } = useLoadableFamily({
    loadable: localProfileByPoiIdFetcher,
    variables: poiId,
    fetchPolicy: 'store-or-network',
  })

  return localProfileByPoiIdResponse
}

export default useLoadableLocalProfileByPoiId
