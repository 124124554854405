import { ActivityComponentType } from '@stackflow/react'
import { useEffect } from 'react'

import { karrotBridge } from '@src/bridge'
import { APP_SCHEME, BIZ_PROFILE_APP_BASE_URL } from '@src/constants/url'
import { createQueryString } from '@src/js-utils/url'
import useLoadableLocalProfileByPoiId from '@src/place-utils/review/store/localProfileByPoiIdLoadableFamily'
import { PageLoading } from '@src/react-utils/components/Loading'
import { useActivity, useNavigator } from '@src/stackflow'

const PoiDetailRedirection: ActivityComponentType<{ poiId: string; referrer: string }> = ({
  params: { poiId, referrer },
}) => {
  const { pop, replace } = useNavigator()
  const { isRoot, name: activityName } = useActivity()
  const localProfile = useLoadableLocalProfileByPoiId(Number(poiId))

  useEffect(() => {
    const localProfileId = localProfile?.data?.id

    if (!localProfileId) {
      replace('fallback', {}, { animate: false })

      return
    }

    const queryString = createQueryString({
      referrer: referrer ?? `place.${activityName}`,
      entry: `place.${activityName}`,
    })
    const remote = `${BIZ_PROFILE_APP_BASE_URL}/local_profiles/${localProfileId}/home${queryString}`

    if (isRoot) {
      karrotBridge.replaceRouter({
        router: {
          remote,
          navbar: false,
          scrollable: false,
        },
      })
    } else {
      pop()
      window.location.href = `${APP_SCHEME}minikarrot/router?remote=${remote}`
    }
  }, [activityName, isRoot, localProfile?.data?.id, pop, referrer, replace])

  return <PageLoading />
}

export default PoiDetailRedirection
